<template>
  <div class="app">
    <div class="content contact-us">
      <section class="hero-section">
          <p class="hero-p">Estamos para guiarte desde el principio</p>
      </section>
      <section class="flex-cols cols-gap-22 flex-valign-center">
        <p class="next-section-question"><a href="https://calendly.com/ragnalabs/discovery" target="_blank">Agendar una llamada.</a></p>
        <img class="og" src="../assets/img/og-ragna-labs.jpg" alt="">
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'ContactUs',
  components: {
    Footer
  },
  mounted () {
    document.title = 'Ragna Labs - Contacto'
  }
}
</script>
