<template>
    <div v-if="isVisible" class="modal">
      <div class="modal-content">
        <span class="close" @click="close">&times;</span>
        <slot></slot>
      </div>
    </div>
  </template>
<script>
export default {
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    open () {
      this.isVisible = true
    },
    close () {
      this.isVisible = false
    }
  }
}
</script>
<style>
.modal {
display: flex;
position: fixed;
z-index: 1;
left: 0;
top: 0;
width: 100%;
height: 100%;
overflow: auto;
background-color: rgba(0, 0, 0, 0.4);
justify-content: center;
align-items: center;
}
.modal-content {
background-color: #fefefe;
margin: auto;
padding: 20px;
border: 1px solid #888;
width: 80%;
}
.close {
color: #aaa;
float: right;
font-size: 28px;
font-weight: bold;
}
.close:hover,
.close:focus {
color: black;
text-decoration: none;
cursor: pointer;
}
</style>
