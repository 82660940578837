<template>
  <div class="app">
    <div class="content home">
      <h1 class="hero-h1">
        Design Consulting.<br />
        UX. Branding.<br />
        Strategy. <router-link to="/about">&More.</router-link>
      </h1>
    </div>
    <Footer />
    <div class="bg-home"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from './Footer.vue'

export default {
  name: 'App',
  components: {
    Footer
  },
  mounted () {
    document.title = 'Ragna Labs — Design Consultancy'
  }
}
</script>

<style scoped>
  .footer {
    background-color: transparent;
    color: #FFFFFF;
    padding: 16px;
    margin-top: 30px;
  }
  @media (max-width: 767px) {
    .footer {
      padding: 0;
    }
  }

</style>
