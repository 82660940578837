<template>
  <div class="app">
    <div class="content work">
      <section class="hero-section">
          <p class="hero-p">Nuestra apuesta es evolucionar a través de la metodología del diseño</p>
      </section>
      <section>
        <h1>Trabajo</h1>
        <div class="big-p">
          <p>Hacemos lo que un diseñador sabe hacer mejor: observar, escuchar, conectar los puntos y comprender profundamente los desafíos para encontrar las mejores soluciones.</p>
        </div>
      </section>
      <section>
        <h2>Methodology</h2>
        <h3>La metodología del diseño</h3>
        <div class="grid-layout-3a1 grid-3a1-md text-center medium-p">
          <div class="pink-bg feature-square-box"><p><span><img src="../assets/img/explorar.svg" alt="Explorar"></span><br/>Explorar<br/>los desafíos y necesidades</p></div>
          <div class="pink-bg feature-square-box"><p><span><img src="../assets/img/idear.svg" alt="Idear"></span><br/>Idear<br/>con el foco en las personas</p></div>
          <div class="pink-bg feature-square-box"><p><span><img src="../assets/img/construir.svg" alt="Construir"></span><br/>Construir<br/>soluciones y procesos</p></div>
        </div>
      </section>
        <section>
          <p class="next-section-question add-margin">Si no sabes por dónde empezar, <router-link to="/contact-us">aquí te ayudamos a descubrirlo.</router-link></p>
        </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'Work',
  components: {
    Footer
  },
  mounted () {
    document.title = 'Ragna Labs — Trabajo'
  }
}
</script>
