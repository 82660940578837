<template>
    <div>
      <!-- Question 1 -->
      <div v-if="step === 1">
        <p>¿De qué forma te avisamos?</p>
        <p>No usaremos este contacto para ningún otro fin</p>
        <button @click="setContactMethod('email')">Email</button>
        <button @click="setContactMethod('Whatsapp')">Whatsapp</button>
      </div>

      <!-- Question 2 -->
      <div v-if="step === 2">
        <p>¿Cuál es tu {{ contactMethod }}?</p>
        <input v-model="contactInfo" :placeholder="contactMethod === 'email' ? 'email@email.com' : '+56912345678'">
        <button @click="goBack">Atrás</button>
        <button @click="submitForm">Enviar</button>
      </div>
    </div>
  </template>

<script>
export default {
  data () {
    return {
      step: 1,
      contactMethod: null,
      contactInfo: ''
    }
  },
  methods: {
    setContactMethod (method) {
      this.contactMethod = method
      this.step = 2
    },
    goBack () {
      this.step = 1
    },
    /*     submitForm () {
      // Handle form submission: console.log(`Contact Method: ${this.contactMethod}, Info: ${this.contactInfo}`),
    }, */
    submitForm () {
      /* fetch('https://your-backend-url.com/submit', { // Replace with your actual backend URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          contactMethod: this.contactMethod,
          contactInfo: this.contactInfo
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data)
        // Handle success (e.g., show a success message)
        })
        .catch((error) => {
          console.error('Error:', error)
        // Handle error (e.g., show an error message)
        }) */
    }
  }
}
</script>
