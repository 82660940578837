<template>
  <div class="faq" @click="$emit('toggle')">
    <div class="header">
      <div class="question">{{ faq.question }}</div>
      <div class="icon" :class="{ 'open': faq.isOpen }">✕</div>
    </div>
    <div class="answer" :class="{ 'open': faq.isOpen }">
      <p>{{ faq.answer }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Question',
  props: {
    faq: {
      type: Object,
      required: true
    }
  }
}
</script>
