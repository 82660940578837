<template>
    <div class="app">
        <div class="content pricing">
            <section class="hero-section">
                <p class="hero-p">Una suscripción para trabajar en flexible, con acceso al expertise de nuestros talentos</p>
            </section>
            <section>
                <h1>Pricing</h1>
                <div class="big-p">
                <p>Pago mensual fijo para empresas y proyectos que necesitan diseño y estrategia constantemente, sin la complejidad de cotizar para cada requerimiento.</p>
                </div>
            </section>
            <section>
                <h2>Precios</h2>
                <div class="grid-layout-3a1 grid-3a1-md medium-p mt-3-3em" id="pricing-table">
                    <div class="stroke-bg pa-1em">
                        <div class="no-seats-available"><p>Sin cupos disponibles</p></div>
                        <p class="pricing-title">Standard</p>
                        <p class="pricing-price-detail">Obtén todos los diseños que necesites en pocos días laborales.</p>
                        <p class="pricing-price">UF 70/mes</p>
                        <p class="pricing-text">Suscripción mensual. Pausa or cancela cuando quieras.</p>
                        <p class="pricing-list-title">Qué incluye:</p>
                        <ul class="pricing-list">
                            <li>Plataforma de solicitudes para ti y/o tu equipo</li>
                            <li>Un requerimiento a la vez</li>
                            <li>Entregables en 3 días laborales promedio</li>
                            <li>Pago sencillo con tarjeta de crédito</li>
                            <li>Pausa tu suscripción sin perder el tiempo restante</li>
                            <li>Cancela en cualquier momento</li>
                        </ul>
                    </div>
                    <div class="stroke-bg pa-1em">
                        <div class="no-seats-available"><p>Sin cupos disponibles</p></div>
                        <p class="pricing-title">Pro</p>
                        <p class="pricing-price-detail">Obtén todos los diseños que necesites en pocos días laborales.</p>
                        <p class="pricing-price">UF 120/mes</p>
                        <p class="pricing-text">Suscripción mensual. Pausa or cancela cuando quieras.</p>
                        <!----<p><span @click="openModal" class="pricing-button">Avisar si se libera un cupo</span></p>-->
                        <modal ref="formModal">
                            <ShortContactForm />
                        </modal>
                        <p class="pricing-list-title">Qué incluye:</p>
                        <ul class="pricing-list">
                            <li>Plataforma de solicitudes para ti y/o tu equipo</li>
                            <li><strong>Dos requerimientos a la vez</strong></li>
                            <li>Entregables en 3 días laborales promedio</li>
                            <li>Pago sencillo con tarjeta de crédito</li>
                            <li>Pausa tu suscripción sin perder el tiempo restante</li>
                            <li>Cancela en cualquier momento</li>
                        </ul>
                    </div>
                    <div class="stroke-bg pa-1em">
                        <p class="pricing-title">Proyectos</p>
                        <p class="pricing-price-detail">Para desafíos mayores.</p>
                        <p class="pricing-price">Conversemos</p>
                        <a href="https://calendly.com/ragnalabs/discovery" target="_blank" class="pricing-button-linear">Agendar una llamada</a>
                        <p class="pricing-list-title">Qué más podemos hacer:</p>
                        <ul class="pricing-list">
                            <li>Consultoría</li>
                            <li>UX Research</li>
                            <li>Diseño UX</li>
                            <li>Diseño UI</li>
                            <li>Estrategia y diseño de marca</li>
                            <li>Copywriting y UX Writing</li>
                            <li>Desarrollo de MVP</li>
                            <li>Desarrollo e-commerce</li>
                            <li>Desarrollo de landing pages</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section>
                <h2>Preguntas frecuentes</h2>
                <Accordion />
            </section>
            <section>
                <p class="next-section-question add-margin">¿Aún con dudas? <router-link to="/contact-us">Conversemos.</router-link></p>
            </section>
        </div>
        <Footer />
    </div>
</template>

<script>
import Modal from './Modal.vue'
import ShortContactForm from '@/components/ShortContactForm.vue'
import Accordion from '@/components/Accordion.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Pricing',
  components: {
    Modal,
    ShortContactForm,
    Accordion,
    Footer
  },
  mounted () {
    document.title = 'Ragna Labs — Pricing'
  },
  methods: {
    openModal () {
      this.$refs.formModal.open()
    }
  }
}
</script>
