import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/styles/main.css'

// Importar LogRocket solo si está habilitado
if (process.env.VUE_APP_LOGROCKET_ENABLED === 'true') {
  const LogRocket = require('logrocket')
  LogRocket.init('br5ouu/ragna-labs-website')
}

createApp(App).use(router).mount('#app')
