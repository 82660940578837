<template>
  <div class="app">
    <div class="content about">
        <section class="hero-section">
            <p class="hero-p">Conectamos los objetivos de las empresas con las aspiraciones de las personas</p>
        </section>
        <section>
          <div class="grid-layout-3a1 grid-3a1-xs text-center pink-bg">
                <div>
                    <div class="quantity-number">9+</div>
                    <div class="quantity-text">años</div>
                </div>
                <div>
                    <div class="quantity-number">70+</div>
                    <div class="quantity-text">proyectos</div>
                </div>
                <div>
                    <div class="quantity-number">10+</div>
                    <div class="quantity-text">industrias</div>
                </div>
            </div>
        </section>
        <section>
          <h1>About</h1>
          <div class="big-p">
            <p>Somos fieles al diseño porque creemos que tiene el potencial de mejorar la vida de las personas. Por eso, nuestra propuesta está en ayudar a empresas que lo hacen posible.</p>
          </div>
        </section>
        <section>
          <h2>Capabilities</h2>
          <div class="grid-layout-3a1 grid-3a1-md">
            <div class="card">
              <div class="text">
                  <h3 class="card-h3">Design Consulting</h3>
                  <p class="small-p">Ayudamos a empresas a desarrollar y ejecutar <strong>ideas</strong> de la mejor manera posible. Creemos en el poder del diseño para la <strong>transformación</strong> de negocios y creación de experiencias, productos y servicios que las personas puedan disfrutar y recordar.</p>
              </div>
            </div>
            <div class="card">
              <div class="text">
                  <h3 class="card-h3">User Experience & Research</h3>
                  <p class="small-p">Desarrollamos <strong>diseño de experiencia</strong> para conectar marcas, productos y servicios con los usuarios, desde la <strong>investigación</strong> y el entendimiento de sus necesidades y aspiraciones. Con esto, además, optimizamos los costos de desarrollo.</p>
              </div>
            </div>
            <div class="card">
              <div class="text">
                  <h3 class="card-h3">Branding & Strategy</h3>
                  <p class="small-p">Creamos <strong>estrategias</strong> para reforzar los valores de las marcas y expandir su ADN dentro y fuera de las empresas. Transformamos estrategias, valores y proyección a futuro en <strong>marcas e identidades</strong> honestas, memorables y que conecten con las personas.</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <h2>Technologies</h2>
          <h3 class="mt-1em">Diseño & Testing</h3>
          <div class="logo-grid">
            <img src="../assets/img/logos/figma.png" alt="Figma">
            <img src="../assets/img/logos/maze.png" alt="Maze">
          </div>
          <h3 class="mt-1-3em">Lenguajes & Frameworks</h3>
          <div class="logo-grid grid-v-gap">
            <img src="../assets/img/logos/html5.png" alt="HTML5">
            <img src="../assets/img/logos/css3.png" alt="CSS3">
            <img src="../assets/img/logos/js.png" alt="Javascript">
          </div>
          <div class="logo-grid">
            <img src="../assets/img/logos/react.png" alt="React">
            <img src="../assets/img/logos/vue.png" alt="Vue">
            <img src="../assets/img/logos/bootstrap.png" alt="Bootstrtap">
          </div>
          <h3 class="mt-1-3em">CMS & e-Commerce Partners</h3>
          <div class="logo-grid">
            <img src="../assets/img/logos/shopify.png" alt="Shopify">
            <img src="../assets/img/logos/jumpseller.png" alt="Jumpseller">
            <img src="../assets/img/logos/webflow.png" alt="Webflow">
          </div>
        </section>
        <section>
          <p class="next-section-question add-margin">¿Y cómo? <router-link to="/work">Acá te contamos un poco.</router-link></p>
        </section>
    </div>
    <Footer />
  </div>

</template>

<script>
import Footer from './Footer.vue'

export default {
  name: 'App',
  components: {
    Footer
  },
  mounted () {
    document.title = 'Ragna Labs — Somos'
  }
}
</script>
