<template>
  <!-- <div id="nav" class="flex-cols" :class="{ 'scrolled-nav': scrolledNav, 'nav-pages': currentRoute !== '/', 'nav-home': currentRoute === '/' }"> -->
  <div id="nav" class="flex-cols nav-pages">
    <div class="left align-left">
      <router-link to="/">
        Ragna Labs
      </router-link>
    </div>
    <div class="desktop-nav right align-right" v-show="!mobile">
      <div class="nav-link"><router-link to="/about">Somos.</router-link></div>
      <div class="nav-link"><router-link to="/work">Trabajo.</router-link></div>
      <!-- <div class="nav-link"><router-link to="/pricing">Pricing.</router-link></div> -->
      <div class="nav-link"><router-link to="/contact-us">Contacto.</router-link></div>
    </div>
    <div class="desktop-nav right-only-mobile align-right" v-show="mobile">
      <div class="nav-link"><a class="mobile-nav-name">
        <transition name="fade" mode="out-in"><span v-if="$route.name==='About'">Somos.</span></transition>
        <transition name="fade" mode="out-in"><span v-if="$route.name==='Work'">Trabajo.</span></transition>
        <!-- <transition name="fade" mode="out-in"><span v-if="$route.name==='Pricing'">Pricing.</span></transition> -->
        <transition name="fade" mode="out-in"><span v-if="$route.name==='Contact-Us'">Contacto.</span></transition>
        </a>
      </div>
      <div @click="toggleMobileNav" class="nav-link" :class="{ 'mobile-nav-active': mobileNav }">
        <a class="mobile-nav-open">&#8801;</a>
      </div>
    </div>
    <transition name="show-nav">
      <div class="mobile-nav" v-show="mobileNav">
        <div @click="toggleMobileNav">
          <div class="nav-link">
            <p class="mobile-nav-close">&#x2715;</p>
          </div>
          <div class="nav-link"><router-link to="/">Home.</router-link></div>
          <div class="nav-link"><router-link to="/about">Somos.</router-link></div>
          <div class="nav-link"><router-link to="/work">Trabajo.</router-link></div>
          <!-- <div class="nav-link"><router-link to="/pricing">Pricing.</router-link></div> -->
          <div class="nav-link"><router-link to="/contact-us">Contacto.</router-link></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data () {
    return {
      scrolledNav: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null
    }
  },
  created () {
    window.addEventListener('resize', this.checkScreen)
    this.checkScreen()
  },
  computed: {
    currentRoute () {
      return this.$route.path
    }
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
    this.currentRoute = this.$route.path
  },
  methods: {
    toggleMobileNav () {
      this.mobileNav = !this.mobileNav
    },
    updateScroll () {
      const scrollPosition = window.scrollY
      if (scrollPosition > 20) {
        this.scrolledNav = true
        return
      }
      this.scrolledNav = false
    },
    checkScreen () {
      this.windowWidth = window.innerWidth
      if (this.windowWidth <= 780) {
        this.mobile = true
        return
      }
      this.mobile = false
      this.mobileNav = false
    }
  }
}
</script>
