<template>
  <main class="accordion">
      <div class="faqs">
          <Question
              v-for="faq in faqs"
              :key="faq.id"
              :faq="faq"
              @toggle="toggleFaq(faq.id)"
          />
      </div>
  </main>
</template>

<script>
import Question from '@/components/Question.vue'

export default {
  name: 'Accordion',
  components: {
    Question
  },
  data () {
    return {
      faqs: [
        {
          id: 1,
          question: '¿Existe un límite de solicitudes mensuales?',
          answer: 'Tus solicitudes son ilimitadas, puedes agregar todas las que quieras a la cola. Las trabajaremos en orden, una a una (o dos a dos, si tienes el plan Pro).',
          isOpen: false
        },
        {
          id: 2,
          question: '¿Qué tan rápido recibiré los entregables?',
          answer: 'En promedio, la mayoría se completan en 1 a 3 días laborales. Sin embargo, las solicitudes más complejas pueden demorar más.',
          isOpen: false
        },
        {
          id: 3,
          question: '¿Cómo hago una solicitud?',
          answer: 'Tú y/o tu equipo tendrán acceso a un Trello privado para escribir, agregar documentos, wireframes, referencias, videos o incluso audios.',
          isOpen: false
        },
        {
          id: 4,
          question: '¿Qué herramientas usan para los diseños?',
          answer: 'La mayoría de las solicitudes se desarrollan en Figma.',
          isOpen: false
        },
        {
          id: 5,
          question: '¿Qué pasa si no me gusta el resultado?',
          answer: 'No hay problema, continuaremos trabajando en tu solicitud hasta que estés 100% conforme; el tiempo de la suscripción es todo tuyo y lo puedes usar en nuevos diseños o en iteraciones de entregables anteriores.',
          isOpen: false
        },
        {
          id: 6,
          question: '¿Existe una devolución si no me gusta el servicio?',
          answer: 'No ofrecemos devoluciones debido al nivel profesional de lo que entregamos.',
          isOpen: false
        },
        {
          id: 7,
          question: '¿Qué significa pausar mi suscripción?',
          answer: 'Significa que puedes reservar lo restante del mes para otro momento, en caso de que no necesites más diseños en el momento.',
          isOpen: false
        },
        {
          id: 8,
          question: '¿Hay tipos de diseños que no hacen?',
          answer: 'Las suscripciones de Ragna Labs no cubren los siguientes trabajos de diseño: animaciones, modelado 3D, packaging, diseño extenso de impresión (revistas, libros), ni desarrollo web.',
          isOpen: false
        }
      ]
    }
  },
  methods: {
    toggleFaq (faqId) {
      this.faqs = this.faqs.map(faq => faq.id === faqId ? { ...faq, isOpen: !faq.isOpen } : faq
      )
    }
  }
}
</script>
